import request from "@/Rest";
import { VERSIONS_URLS } from "@/Rest/api/urls";

export async function getVersions() {
  try {
    const { data } = await request.GET(VERSIONS_URLS.getVersions);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
