<template>
  <div
    class="lg:flex lg:justify-between bg-white m-2 lg:m-2 p-3 h-screen overflow-auto"
  >
    <div class="w-full px-2 lg:px-8 lg:w-1/2 h-full">
      <h1 class="font-semibold text-xl">Welcome to your Mastermind portal.</h1>
      <h2 class="text-sm">
        Please ensure you have taken the following steps to get started:
      </h2>
      <div class="bg-brightGray mt-8 p-5 text-darkBlue font-medium">
        <div>
          <div class="mt-12 flex items-center">
            <img src="@/assets/icons/review-icon.png" alt="menu" width="40" />
            <p class="ml-3">
              Review the
              <a
                href="https://www.mastermindsports.com/quick-start-guide"
                target="_blank"
                class="text-blue-600 hover:text-blue-800"
                >Quick Start Guide</a
              >
              <em
                class="fa-solid fa-arrow-up-right-from-square ml-1 text-xs text-blue-600"
              ></em
              >.
            </p>
          </div>
          <div class="mt-12 flex items-center">
            <img src="@/assets/icons/download-icon.svg" alt="menu" width="40" />
            <p class="ml-2">
              Download your Mastermind Cognitive Training application through
              the Meta and/or tablet app stores (Apple or Google Play).
            </p>
          </div>
          <div
            class="flex flex-col sm:flex-col md:flex-row ml-12 items-center space-y-2 md:space-y-0 md:space-x-10 sm:space-y-4"
          >
            <a :href="googlePlayLink" target="_blank">
              <img
                src="@/assets/icons/google-play-button.svg"
                alt="Mastermind Cognitive Training"
                class="w-24 h-20"
              />
            </a>
            <a :href="appleAppStoreLink" target="_blank">
              <img
                src="@/assets/icons/app-store-button.svg"
                alt="Mastermind Cognitive Training"
                class="w-24 h-20"
              />
            </a>
            <a :href="oculusLink" target="_blank">
              <img
                src="@/assets/icons/oculus-button.svg"
                alt="Mastermind Cognitive Training"
                class="w-24 h-20"
              />
            </a>
          </div>
          <div class="flex items-center">
            <img
              src="@/assets/icons/credential-icon.png"
              alt="menu"
              width="40"
            />
            <p class="ml-2 text-base">
              Use the same credentials you created for this portal to log in to
              the Meta, Apple, and/or Android application.
            </p>
          </div>
          <div class="my-12 flex items-center">
            <img src="@/assets/icons/training-icon.png" alt="menu" width="40" />
            <p class="ml-2">Start training!</p>
          </div>
        </div>
      </div>
      <p class="mt-5 text-sm text-center">
        You can find more helpful information using the links in the left
        navigation pane. If you have questions, you can reach us at
        <a
          class="underline text-blue-600 hover:text-blue-800"
          href="mailto: support@mastermindsports.com"
        >
          support@mastermindsports.com</a
        >
        <em
          class="fa-solid fa-arrow-up-right-from-square ml-1 text-xs text-blue-600"
        ></em>
      </p>
    </div>
    <div class="hidden lg:flex lg:w-1/2 h-full">
      <img
        class="object-cover h-auto w-full"
        src="@/assets/welcome-image.png"
        alt="Welcome"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getVersions } from "@/services/versions/versions";

const googlePlayLink = ref("");
const appleAppStoreLink = ref("");
const oculusLink = ref("");

onMounted(async () => {
  const versions = await getVersions();
  versions.forEach((version) => {
    if (version.platform === "OCULUS") {
      oculusLink.value = version.link || "#";
    } else if (version.platform === "IOS") {
      appleAppStoreLink.value = version.link || "#";
    } else if (version.platform === "ANDROID") {
      googlePlayLink.value = version.link || "#";
    }
  });
});
</script>
